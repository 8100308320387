<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('market.filters.title') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row />
    </b-card-body>
  </b-card>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'

export default {
  mixins: [GlobalMixin],
  props: {
    categoryFilter: {
      type: [Number, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      categoryOptions: [],
    }
  },
  async beforeCreate() {
    //
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
